body {
  background-color: #c0c0c0;
  font-family:"Fixedsys Excelsior 3.01",monospace;
}

.border {
  border-top: 7px solid #acacac;
  border-left: 7px solid #666;
  border-right: 7px solid #d5d5d5;
  border-bottom: 7px solid #404040;
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0px;
  left: 0px;
}
.app {
  text-align: center;
  padding: 2rem 1vw;
  max-width: 800px;
  margin: auto;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  font-size: 2rem;
  position: relative;
  z-index: 2;
}

.right-header {
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.hidden {
  opacity: 0;
}

.header-buttons {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.button {
  padding: 0.25rem;
  margin: 0.25rem;
  border-top: 4px solid #eee;
  border-left: 4px solid #ddd;
  border-right: 3px solid #666;
  border-bottom: 3px solid #666;
}

.button:hover {
  background-color: #bbb6b6;
}

.button:active {
  border-top: 4px solid #666;
  border-left: 4px solid #e1e1e1;
  border-right: 3px solid #d0d0d0;
  border-bottom: 3px solid #d2d2d2;
  background-color: #a8a6a6;
}

.home {
  padding-top: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
}

.title-text {
  font-size: 4rem;
}

.upload-panel {
  padding: 2rem 2rem 0rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.moth {
  padding: 1rem 1rem 0rem 1rem;
}

.upload-box-container {
  padding: 1rem;
}

.upload-box {
  border-top: 4px solid #666;
  border-left: 4px solid #9d9d9d;
  border-right: 3px solid #ddd;
  border-bottom: 3px solid #ddd;
  background-clip: text;
  background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
  cursor: pointer;
  color: grey;
  padding: 2rem 3rem;
  font-family: arial;
  font-weight: 700;
  font-size: 4rem;
  margin-bottom: 1rem;
  text-shadow: 0px 2px 0px #ddd;
  width: 120px;
  height: 120px;
  margin: 15px auto;
}

.upload-box:active {
  color: grey;
  background-color: #666;
}

.upload-button {
  cursor: pointer;
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
  border-top: 3px solid #eee;
  border-left: 3px solid #ddd;
  border-right: 3px solid #666;
  border-bottom: 3px solid #666;
  width: 170px;
}

.upload-button:active {
  border-top: 4px solid #666;
  border-left: 4px solid #ddd;
  border-right: 3px solid  #eee;
  border-bottom: 3px solid  #eee;
  box-shadow: inherit;
}

.processing-panel-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.processing-panel {
  display: flex;
  flex-direction: column;
  width: 50vw;
  height: 90vh;
  position: absolute;
  z-index: 99;
}

.processing-panel-topbar {
  background: #a1a1a1;
  border-top: 2px solid #c8c8c8;
  border-left: 2px solid #aeaeae;
  border-right: 1px solid #666;
  border-bottom: 1px solid #666;
  box-shadow: 1px 1px 0 #111;
  width: 100%;
  height: 2rem;
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  outline: 0;
}

.processing-panel-close-button {
  color: red;
  cursor: pointer;
}

.processing-panel-body {
  background-color: #60f;
  border-top: 2px solid #111;
  border-left: 2px solid #aeaeae;
  border-right: 1px solid #666;
  border-bottom: 1px solid #666;
  box-shadow: 1px 1px 0 #111;
  color: #fff;
  width: 100%;
  flex-grow: 1;
  text-align: left;
  padding: 2rem;
  outline: 0;
}

.archive {
  margin-top: 2.5rem;
  width: 100%;
  border: 3px solid black;  
  position: relative;
  padding: 1rem;
}

.archive-item-container {
  padding: 1rem;
  border-top: 3px solid grey;
  border-right: 3px solid grey;
  border-left: 3px solid grey;
}

.archive-item-container:last-child {
  border-bottom: 3px solid grey;
}

.archive-item {
  display: flex;
  width: 100%;
  border: 1px solid black;
}

.archive-item-image {
  border-right: 1px solid black;
  max-width: 20rem;
}

.archive-item-body {
  text-align: left;
  justify-content: flex-start;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.archive-item-details {
  padding: 1rem;
  width: 100%;
  border-bottom: 1px solid black;
  background-color: #ccc;
}

.archive-item-text {
  flex-grow: 1;
  padding: 1rem;
  width: 100%;
}

/*
mobile app
*/

.mobile-app {
  /* width: 100vw;
  height: 100vh; */
}

.mobile-body {
  font-size: 1.3rem;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  min-height: 50vh;
  min-width: 50vw;
  max-height: 90vh;
  max-width: 90vw;
  display: flex;
  flex-direction: column;
}

.mobile-text-holder {
  width: 100%;
  border-top: 4px solid #eee;
  border-left: 4px solid #ddd;
  border-right: 3px solid #666;
  border-bottom: 3px solid #666;
  padding: 1rem;
}

.mobile-buttons-holder {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.mobile-button {
  border-top: 4px solid #eee;
  border-left: 4px solid #ddd;
  border-right: 3px solid #666;
  border-bottom: 3px solid #666;
  padding: 0.3rem 0.7rem;
  width: 120px;
}